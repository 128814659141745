<template>
    <div class="about">
        <div class="top">
            <img :src="topImg" alt="">
            <div class="topstr">
                <h2>关于我们</h2>
                <h3>广州医键通软件开发有限公司旗下网站（医键通）</h3>
                <p>精于设计+技术创新，<br>
                    提供精致后勤软件服务的医键通</p>
            </div>
        </div>
        <div class="question">
            <div class="introduce">
                    <p>创办医键通之前，核心成员一起在移动互联网行业从事了将近7年的游戏软件设计以及偏底层的系统开发工作。2016年，在医院后勤领域深耕了20年的泰科物业公司，希望将软件行业开发技术和泰科经验相结合，量身定制一套高效完整的后勤管理工具。因此，成立了广州医键通软件开发有限公司。</p><br>

                    <p>医键通团队软件团队的初衷是希望为用户提供近乎极致的用户体验和技术服务。我们致力于解决应用统计查询效率低的问题，移动App方面，我们不断地提升软件的性能及减轻对网络的依赖，我们一直在为开拓前沿移动技术而不懈地努力。</p><br>

                    <p>医键通的技术能力非常强，是业界少有的使用Go语言作为底层开发的公司之一；新技术的支持让我们的系统更加的稳定，我们仍然在深入地探索之中。</p><br>

                    <p>医键通始终致力于提供高质量的客户服务，作为您的合作伙伴，我们将发扬孜孜不倦的工作精神，确保您的软件系统为您而设计，因您而设计。这是我们的使命，我们将携手共进。</p>
            </div>
            <div class="questionbox">
                <div class="questionmaintitle">
                    <img :src="questionIcon1" alt="">
                    <h2>您可能感兴趣的内容</h2>
                </div>
                <div class="questiontitlebox">
                    <h3>#1-如何选择软件公司</h3>
                </div>
                <div class="answerbox">
                    运行医键通需要配备电脑及移动设备，电脑系统win7以上、手机系统在安卓4.0以上，我们可以代企业配备，摒弃纳入售后服务中，服务器我们会使用阿里云服务器。针对某些特殊情况，我们建议采购本地服务器。这些在有需求时，将会被列入清单中由您自由选择。
                </div>
                <div class="questiontitlebox">
                    <h3>#2-医键通未来的定位是怎样的</h3>
                </div>
                <div class="answerbox">
                    医键通未来仍然会专注于医疗行业后勤管理系统的开发，对于其他的行业和领域也会有所涉及。我们将会继续坚持以技术为本的原则，注重底层技术的积累，打造一支更加强大、更加高效的开发团队。
                </div>
                <div class="anymore">
                    <p>这里找不到您的答案？或者您想了解更多</p>
                    <router-link to="/experience">请把您的想法告诉我们</router-link>
                </div>
            </div>
        </div>
        <div class="help">
            <div class="helphead">
                <img :src="Icon1" alt="">
                <span>使用帮助</span>
            </div>
            <div class="helpbody">
                <div class="help1">
                    <h3>#1-软件配置要求</h3>
                    <p>
                        在企业配备医键通软件系统时<br>
                        需要什么样的硬件支持？<br>
                        企业需要额外采购什么样的设备<br>
                        研发周期是多久？是否需要<br>
                        在本地布置服务器？
                    </p>
                </div>
                <div class="showmore" v-show="!showMore" @click="showMore = !showMore">了解更多</div>
                <div class="help2" v-show="showMore">
                    <div class="help2head">
                        <img :src="questionIcon2" alt="">
                        <h2>常见问题</h2>
                    </div>
                    <div class="help2question">
                        <h3>#1-软件配置要求</h3>
                    </div>
                    <div class="help2answer">
                        问：运行医键通系统需要什么软件配置？
                        <br><br>
                        答：涉及到网页版、手机两个方面，网页版需要IE8.0以上浏览器，推荐使用谷歌浏览器进行访问。手机方面，安卓需要4.0系统、苹果IOS5以上即可运行。两者都需要稳定的网络访问。正常情况下，我们会为客户配置阿里云服务，如果涉及到特殊环境，我们建议搭建本地服务器。这些在有需求时，将会被列入清单中供您自由选择。
                    </div>
                    <div class="help2question">
                        <h3>#2-软件的使用权及收费</h3>
                    </div>
                    <div class="help2answer">
                        问：软件如何收费？<br>
                        答：可以按2种方式展开合作。第一种：使用泰科物业公司的后勤服务，软件将免费提供给企业使用。在合同期内免费二次开发及享受同等升级服务。第二种：按服务租用软件服务，可按功能模块制定符合企业的软件，按收取年费方式提供服务，在服务期内享受同等升级服务及二次开发服务。
                    </div>
                    <div class="help2question">
                        <h3>#3-是否支持第三方接口</h3>
                    </div>
                    <div class="help2answer">
                        问：是否支持第三方接口对接及第三方二次开发。
                        答：我们的软件操作便利、灵活，可根据实际情况进行调整，各功能可按需定制，对接接口可按实际需求进行调整及研发。暂不支持第三方二次开发，未来会开放二次开发工具用于接入第三方插件功能。
                    </div>
                </div>
            </div>
        </div>
        <div class="contact">
            <div class="contacthead">
                <img :src="Icon2" alt="">
                <span>需要咨询</span>
            </div>
            <div class="contactbox">
                <p>
                    公司电话：400-992-3006 <br>
                    地址：广州市天河区林和西路155号中汇广场公寓楼3006房
                </p>             
            </div>
        </div>
        <says/>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import foots from '@/components/mobile/foot'
import says from '@/components/mobile/say'
import hotline from '@/components/mobile/hotline.vue'
export default {
    components:{
        foots,
        says,
        hotline
    },
    data(){
        return{
            topImg:require('@/assets/img/mobileImg/productImg/bg1.png'),
            questionIcon1:require('@/assets/img/pcImg/aboutImg/about-icon-content.png'),
            questionIcon2:require('@/assets/img/pcImg/aboutImg/about-icon-question.png'),
            Icon1:require('@/assets/img/pcImg/aboutImg/about-sidebar-helo.png'),
            Icon2:require('@/assets/img/pcImg/aboutImg/about-sidebar-icon-contact.png'),
            showMore:false,
        }
    }
}
</script>

<style lang="less" scoped>
.about{
    width: 23.4375rem;
    .top{
        height: 25rem;
        width: 23.4375rem;
        color: #FFFFFF;
        img{
            width: 100%;
            position: relative;
            z-index: -5;
        }
        .topstr{
            width: 20.625rem;
            margin: -21rem auto 0 ;
            h2{
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
            h3{
                font-size: 0.875rem;
                font-weight: 400;
                margin: 2.375rem 0 0.625rem 0;
            }
            p{
                font-size: 1.125rem;
                font-weight: bold;
            }
        }
        
    }
    .question{
        margin: -7.5rem 0 0 0;
        width: 23.4375rem;
        height: 63.75rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.9375rem 2.6875rem rgba(0, 0, 0, 0.05);
        // background-color: rosybrown;
        border-radius: 0.875rem;
        .introduce{
            float: left;
            text-indent:2em;
            width: 19.6875rem;
            height: 27.625rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.1875rem;
            color: #697598;
            margin: 1.875rem;
            // background-color: saddlebrown;
        }
        .questionbox{
            float: left;
            .questionmaintitle{
                width: 23.4375rem;
                height: 1.75rem;
                // background-color: slategrey;
                margin: 0.625rem 0 1.875rem 0;
                img{
                    float: left;
                    width: 1.75rem;
                    margin: 0 0 0 1.3125rem;
                }
                h2{
                    float: left;
                    font-size: 1.125rem;
                    font-weight: bold;
                    line-height: 1.75rem;
                    color: #31384E;
                }
            }
            .questiontitlebox{
                width: 21.5625rem;
                height: 2.5rem;
                background: #FAFBFE;
                border-radius: 0.625rem;
                margin: 0 auto;
                h3{
                    margin-left: 1.25rem;
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 2.5rem;
                    color: #31384E;
                }
            }
            .answerbox{
                width: 19.375rem;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.1875rem;
                color: #697598;
                margin: 1.25rem auto;
            }
            .anymore{
                width: 19.375rem;
                margin: 0 auto;
                p{
                    font-size: 0.875rem;
                    font-weight: bold;
                    color: #31384E;
                }
                a{
                    font-size: 0.875rem;
                    font-weight: bold;
                    color: #207BFA;
                }
            }
        }
        
    }
    .help{
        .helphead{
            width: 23.4375rem;
            height: 4.375rem;
            background: #FAFBFE;
            border-radius: 0.1875rem 0.1875rem 0px 0px;
            img{
                // width: 1.625rem;
                float: left;
                margin: 1rem 0.3rem 0 1.625rem;
            }
            span{
                float: left;
                font-size: 1rem;
                font-weight: bold;
                color: #31384E;
                line-height: 4.375rem;
            }
        }
        .helpbody{
            .help1{
                margin: 1.25rem 0 0 3.1875rem;
                h3{
                    font-size: 0.875rem;
                    font-weight: bold;
                    color: #31384E;
                    margin: 0 0 1.25rem 0;
                }
                p{
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.1875rem;
                    color: #31384E;
                }
            }
            .showmore{
                font-size: 0.875rem;
                font-weight: bold;
                color: #207BFA;
                margin: 1.875rem 0 1.875rem 3.1875rem;
            }
            .help2{
                .help2head{
                    margin: 2.25rem 0 1.5625rem 0;
                    img{
                        float: left;
                        width: 1.75rem;
                        margin: 0 0.9375rem 0 1.3125rem;
                    }
                    h2{
                        font-size: 1.125rem;
                        font-weight: bold;
                        line-height: 1.75rem;
                        color: #31384E;
                    }
                }
                .help2question{
                    width: 21.5625rem;
                    height: 2.5rem;
                    background: #FAFBFE;
                    border-radius: 0.625rem;
                    font-weight: bold;
                    line-height: 2.5rem;
                    color: #31384E;
                    margin: 0 auto;
                    h3{
                        margin: 0 0 0 1.25rem;
                        font-size: 0.875rem;
                    }
                }
                .help2answer{
                    margin: 1.25rem auto;
                    width: 19.375rem;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 1.1875rem;
                    color: #697598;
                }
            }
        }
        
    }
    .contact{
        .contacthead{
            width: 23.4375rem;
            height: 4.375rem;
            background: #FAFBFE;
            border-radius: 0.1875rem 0.1875rem 0px 0px;
            img{
                width: 1.875rem;
                margin: 1.25rem 0.875rem 0 1.25rem;
                float: left;
            }
            span{
                font-size: 1rem;
                font-weight: bold;
                line-height: 4.375rem;
                color: #31384E;
            }
        }
        .contactbox{
            padding: 1.25rem 0 1.875rem 0;
            p{
                width: 18.4375rem;
                margin: 0 auto;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5rem;
                color: #207BFA;
            }
            
        }
    }
    /deep/ .foot{
    background-color: #fff;
    }
}
</style>